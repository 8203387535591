export const en = {
  // 请求成功
  0: 'ok',
  // 系统
  1000: 'system exception',
  1001: 'parameter exception',
  1002: 'login expired',
  1003: 'connect mysql fail',
  1004: 'server is running',
  1005: 'Server is updating, please try again later',
  // 用户角色管理
  1100: 'username or password fail',
  1101: 'account is disabled',
  1102: 'user does not exist',
  1103: 'user id is err',
  1104: 'username is err',
  1105: 'username exist',
  1106: 'cannot delete yourself',
  1107: 'original password is wrong',
  1108: 'password is different twice',
  1109: 'role exist',
  1110: ' role does not exist',
  1111: 'role is using by user cannot delete',
  1112: 'permission denied',
  // 配置管理
  1200: 'group does not exist',
  1201: 'server exist',
  1202: 'server ip is error',
  1203: 'username cannot be empty',
  1204: 'password cannot be empty',
  1205: 'server type does not exist',
  1206: 'server does not exist',
  1207: 'group exist',
  1208: 'group params missing',
  1209: 'push profile does not exist',
  1210: 'push profile wrong format',
  1211: 'profile params wrong',
  1212: 'group file error',
  1213: 'new group exist',
  1214: 'get ims uid fail',
  1215: 'domain not set',
  1216: 'ndselect not set',
  1217: 'server not exist',
  1218: 'domain format error',
  1219: 'ssh port error',
  1220: 'ilive-manager-server is required',
  1221: 'group in using by project',
  1222: 'profile in using by project',
  1223: 'turn push configs error',
  1224: 'turn push config not exists',
  1225: 'default group can not delete',
  1226: 'turn push type not exists',
  1227: 'turn push config exists',
  1228: 'push profile is inconsistent',
  1229: 'group name must be letter or number',
  1230: 'no ndselect',
  1231: 'get ims version fail',
  // 日志管理
  1300: 'log does not exist',
  1301: 'merge log fail',
  // project管理
  1400: 'project not exist',
  1401: 'project exist',
  1402: 'project file error',
  1403: 'global_configs missing',
  1404: 'streaming_configs missing',
  1405: 'global_configs item missing',
  1406: 'group or profile not exist',
  1407: 'working_mode does exist',
  1408: 'global_configs or streaming_configs missing',
  1409: 'video_impl must be number',
  1410: 'new project exist',
  1411: 'video_format must be number',
  1412: 'codec_name is missing',
  1413: 'working mode not exist',
  1414: 'project in using by terminal',
  1415: 'default project can not delete',
  1416: 'project file format error',
  1417: 'working_mode must be number',
  1418: 'project name must be letter or number',
  1419: 'Please post the group first',
  1420: 'server type is wrong',
  1421: 'project not exist in server',
  1422: 'streaming_config missing param',
  1423: 'color_space must be number',
  1424: 'streaming_config is not a dict',
  1426: 'customize_impl must be number',
  1427: 'customize_format must be number',
  1428: 'impl not exist',
  1429: 'impl format mismatch',
  1430: 'impl device_index mismatch',
  1431: 'impl device mismatch',
  1432: 'RTSP URL missing',
  1433: 'rtmp_capturer_addr missing',
  1434: 'resolution must be number',
  1435: 'fps must be number',
  1436: 'extern_param must be json',
  1437: 'x, y, z, w, h must be number',
  1438: 'output_param_mode and output_param_index must be number',
  1439: 'sub_video_capture_param_list must be list',
  1440: 'output_param_index is out of sub_video_capture_param_list',
  1441: 'splicing_pattern_list must be list',
  1442: 'splicing_pattern_list and sub_video_capture_param_list must be equal lengths',
  1443: 'extension_params must be json',
  1444: 'video_capture_param error',
  1445: 'audio_capture_param param missing',
  1446: 'audio_sample_rate and audio_channel_num must be number',
  1447: 'audio_capture_param format is wrong',
  1448: 'project format is wrong',
  1449: 'log param must be number',
  1450: 'pzvt_refresh_interval must be number',
  1451: 'splicing_pattern param is missing',
  1452: 'the project file does not support editing',
  1453: 'splicing_pattern can not be empty',
  1454: 'splicing_pattern item can not be self',
  1455: 'streaming_config_index must be number',
  1456: 'streaming_config_index is out of streaming_configs',
  1457: 'data_source missing',
  1458: 'sub_video_capture_param can not be splicing_pattern',
  1459: 'resolution must be number',
  1460: 'fps must be number',
  1461: 'video_enc_format must be number',
  //
  1500: 'server does not exist',
  1501: 'server type mismatch',
  1502: 'stream list is refreshing',
  // 服务器管理
  1601: 'server not exist',
  1602: 'server not support health check',
  1603: 'health check fail',
  1604: 'main imanager server exist',
  1605: 'domain format error',
  1606: 'generate cert fail',
  1607: 'cert not exist',
  1608: 'main imanager server is missing',
  1609: 'server can not connect',
  1610: 'management_port error',
  1611: 'data_port error',
  1612: 'region not exist',
  1613: 'region name exist',
  1614: 'default region can not delete',
  1615: 'default region can not update',
  1616: 'default region can not send',
  1617: 'main imanager server can not delete',
  1618: 'service ip is necessary',
  1619: 'region is being used',
  1620: 'management ip can not modify',
  1621: 'server type conflict',
  1622: 'server can not update',
  1623: 'ims server update package not exist',
  1624: 'must be ims server',
  1625: 'get stream list fail',
  1626: 'activate file not exist',
  1627: 'channel.sta file not exists',
  1628: 'ndselect is difference',
  // 终端管理
  1700: 'terminal not exist',
  1701: 'device_id exists',
  1702: 'terminal info item missing',
  1703: 'lid exist',
  1704: 'terminal type error',
  1705: 'lid missing',
  1706: 'command not exist',
  1707: 'terminal group exist',
  1708: 'log level error',
  1709: 'terminal group name can not be empty',
  1710: 'terminal group not exist',
  1711: 'terminal package not upload',
  1712: 'terminal group is empty',
  1713: 'terminal activate file not exist',
  1714: 'terminal license file not exist',
  1715: 'no activate file',
  1716: 'error compressing file directory',
  1717: 'device_id is inconsistent with lid',
  1718: 'terminal ID must be letter or number or _',
  1800: 'compressed file is missing',
  1801: 'compressed file corrupted',
  1802: 'se file is not compatible with ims'
}

export const zh = {
  // 请求成功
  0: '成功',
  // 系统
  1000: '系统异常',
  1001: '参数异常',
  1002: '登录已过期',
  1003: '连接数据库失败，请稍后再试',
  1004: '服务器正在运行',
  1005: '服务器正在升级，请稍后再试',
  // 用户角色管理
  1100: '用户名或密码错误',
  1101: '帐户已被禁用',
  1102: '用户不存在',
  1103: '用户 ID 错误',
  1104: '用户名错误',
  1105: '用户名已存在',
  1106: '不能删除自己',
  1107: '原密码错误',
  1108: '密码两次不同',
  1109: '角色已存在',
  1110: '角色不存在',
  1111: '角色正在被用户使用,无法删除',
  1112: '没有权限',
  // 配置管理
  1200: 'group不存在',
  1201: '服务器已存在',
  1202: '服务器 ip 错误',
  1203: '用户名不能为空',
  1204: '密码不能为空',
  1205: '服务器类型不存在',
  1206: '服务器不存在',
  1207: 'group已存在',
  1208: 'group参数缺失',
  1209: '推送配置文件不存在',
  1210: '推送配置文件格式错误',
  1211: '配置文件参数错误',
  1212: 'group文件错误',
  1213: '新的group已存在',
  1214: '获取 ims uid 失败',
  1215: '未设置域名',
  1216: '未设置 ndselect',
  1217: '服务器不存在',
  1218: '域名格式错误',
  1219: '端口错误',
  1220: '请先配置ilive-manager服务',
  1221: 'group 正在被Project使用',
  1222: 'profile正在被Project使用',
  1223: '转推配置错误',
  1224: '转推配置不存在',
  1225: '默认group不能删除',
  1226: '转推类型不存在',
  1227: '转推配置已存在',
  1228: '推送配置文件不一致',
  1229: 'group名称必须是字母或数字',
  1230: '没有 ndselect',
  1231: '获取ims版本号失败',
  // 日志管理
  1300: '日志不存在',
  1301: '合并日志失败',
  // project管理
  1400: 'project 不存在',
  1401: 'project已存在',
  1402: 'project文件错误',
  1403: '基本配置缺失',
  1404: '流配置缺失',
  1405: '基本配置项缺失',
  1406: 'group 或 profile不存在',
  1407: '采集工作模式不存在',
  1408: '基本配置或流配置缺失',
  1409: '采集类型必须是一个数字',
  1410: '新project已存在',
  1411: '数据格式必须是一个数字',
  1412: '视频编码类型缺失',
  1413: '工作模式不存在',
  1414: 'project正在被终端使用',
  1415: '默认project不能删除',
  1416: 'project文件格式错误',
  1417: '采集工作模式必须是一个数字',
  1418: 'project名称必须是字母或数字',
  1419: '请先发布group',
  1420: '服务类型错误',
  1421: '服务器中不存在project',
  1422: '流配置确实参数',
  1423: '色彩空间必须为数字',
  1424: '流配置不是一个字典',
  1426: '自定义采集类型必须是一个数字',
  1427: '自定义数据格式必须是一个数字',
  1428: '采集类型不存在',
  1429: '采集类型格式不匹配',
  1430: '采集类型设备编号不匹配',
  1431: '采集类型设备名称不匹配',
  1432: 'RTSP URL 缺失',
  1433: 'RTMP URL 缺失',
  1434: '分辨率必须是一个数字',
  1435: '贞率必须是一个数字',
  1436: '外部采集模块参数必须是一个JSON',
  1437: 'x, y, z, w, h 必须是一个数字',
  1438: '主画面编号和拼接模式必须是一个数字',
  1439: '拼接模式子视频采集参数必须是一个列表',
  1440: '主画面编号不在sub_video_capture_param_list中',
  1441: '拼接模式必须是一个列表',
  1442: '拼接模式列表和拼接模式子视频采集参数长度必须相等',
  1443: '扩展参数必须是一个JSON',
  1444: '视频采集参数错误',
  1445: '音频采集参缺失',
  1446: '采样率和声道数必须是一个数字',
  1447: '音频采集参格式错误',
  1448: 'project 数据格式错误',
  1449: '日志参数必须是一个数字',
  1450: '虚拟时钟同步间隔必须是一个数字',
  1451: '拼接模式参数缺失',
  1452: '该Project不支持编辑',
  1453: '拼接模式不能为空',
  1454: '拼接模式项不能为自己',
  1455: '流序号必须是一个数字',
  1456: '流序号超出了流配置',
  1457: 'data_source 缺失',
  1458: '子视频采集参数不能是拼接模式',
  1459: '分辨率必须是一个数字',
  1460: '帧率必须是一个数字',
  1461: '视频编码格式必须是一个数字',
  //
  1500: '服务器不存在',
  1501: '服务类型不匹配',
  1502: '流列表正在刷新',
  // 服务器管理
  1601: '服务器不存在',
  1602: '服务器不支持健康检查',
  1603: '健康检测失败',
  1604: '主 imanager 服务器已存在',
  1605: '域名格式错误',
  1606: '生成证书失败',
  1607: '证书不存在',
  1608: '缺少主imanager服务器',
  1609: '服务器不能连接',
  1610: '管理端口错误',
  1611: '数据端口错误',
  1612: '区域不存在',
  1613: '区域名称已存在',
  1614: '默认区域不能删除',
  1615: '默认区域不能更新',
  1616: '默认区域不能下发',
  1617: '主服务器不能被删除',
  1618: '服务ip是必须的',
  1619: '区域正在被使用',
  1620: '管理ip不能被修改',
  1621: '服务类型冲突',
  1622: '服务器不能更新',
  1623: 'ims服务器更新包不存在',
  1624: '必须是 ims 服务器',
  1625: '获取流列表失败',
  1626: '激活文件不存在',
  1627: 'channel.sta 文件不存在',
  1628: 'ndselect 不同',
  // 终端管理
  1700: '终端不存在',
  1701: '终端id已存在',
  1702: '终端信息缺失',
  1703: 'lid 已存在',
  1704: '终端类型错误',
  1705: 'lid 缺失',
  1706: '命令不存在',
  1707: '终端分组已存在',
  1708: '日志级别错误',
  1709: '终端分组名称不能为空',
  1710: '终端分组不存在',
  1711: '终端包未上传',
  1712: '终端分组为空',
  1713: '终端激活文件不存在',
  1714: '终端证书不存在',
  1715: '没有授权文件',
  1716: '压缩文件目录时出错',
  1717: 'device_id 与 lid 不一致',
  1718: '终端ID必须是字母或数字或 _',
  1800: '压缩文件缺失',
  1801: '压缩文件损坏',
  1802: '场景文件与ims不兼容'
}

export default { en, zh }
