import request from './request'

/**
 * @description: 登录
 * @param {
 * username: String,
 * password: String
 * }
 */
export function login (data) {
  return request({
    url: '/user_api/login',
    method: 'post',
    data
  })
}

/**
 * @description: 修改密码
 * @param {
 * SAID: Number,  管理员ID
 * OldPassword: String,
 * Password: String,
 * Password1: String
 * }
 */
export function changePass (data) {
  return request({
    url: '/user_api/user/password',
    method: 'post',
    data
  })
}
// 获取版本号
export function get_version () {
  return request({
    url: '/sys/sys_version',
    method: 'get'
  })
}

// 获取下拉选择框选择项列表
export function get_sename_list (data) {
  return request({
    url: '/sys/select_options',
    method: 'post',
    data: { select_language: localStorage.lang, ...data }
  })
}
