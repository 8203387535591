const en = {
  common: {
    open: 'Turn on',
    close: 'Turn off',
    isConfirmSelectAll: 'Is the current state of select all to confirm the operation?',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    deleteDetail: 'Delete Detail',
    deleteResult: 'Delete Result',
    empty: 'No data',
    searchSuc: 'Search successful',
    resultShow: 'Results display',
    send: 'Publish',
    pullStream: 'Pull Stream',
    detail: 'Detail',
    port: 'Port',
    customize: 'Customize',
    push: 'Pusher',
    pull: 'Player',
    pushAndPull: 'Pusher&Player',
    uploadSuc: 'Uploaded successfully',
    online: 'Online',
    offline: 'Offline',
    filter: 'Filter',
    analysising: 'Analysising',
    domain: 'Domain',
    ipRemind: 'Please enter the correct IP address',
    required: 'Please fill in this field',
    check: 'Please fill out the form as required',
    succ: 'Success',
    fail: 'Fail',
    loading: 'Loading',
    deleteing: 'Deleting',
    changing: 'Modifying',
    systemName: 'iLive-Manager management system',
    account: 'Account',
    password: 'Password',
    newpass: 'New Password',
    inputPassword: 'Input Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    add: 'Add',
    changeSuccess: 'Successfully modified',
    deleteSuccess: 'Successfully deleted',
    find: 'Find',
    fresh: 'Fresh',
    deleteSelect: 'Delete selected',
    edit: 'Edit',
    delete: 'Delete',
    email: 'Email',
    operate: 'Operate',
    normal: 'Normal',
    disable: 'Disabled',
    remind: 'Remind',
    remark: 'Remark',
    isConfirmDelete: 'Are you sure you want to delete?',
    isConfirmEdit: 'Are you sure you want to edit?',
    confirmAdd: 'Confirm add',
    pleaseChoose: 'Please choose',
    addSuccess: 'Added successfully',
    confirmEdit: 'Confirm edit',
    username: 'Username',
    updateSuccess: 'Update completed',
    yes: 'Yes',
    no: 'No',
    import: 'Import',
    export: 'Export',
    importSuc: 'Import successful',
    minis: 'Min is ',
    maxis: 'Max is ',
    notLess: 'Cannot be less than '
  },
  time: {
    to: 'To',
    last1h: 'last hour',
    last4h: 'last 4 hours',
    last24h: 'last 24 hours',
    last1day: 'last day',
    last3days: 'last three days',
    last1week: 'last week',
    last1month: 'last month',
    timeRange: 'Period',
    startTime: 'Start Time',
    endTime: 'End Time',
    startDate: 'Start Date',
    endDate: 'End Date'
  },
  form: {
    number: 'Please key in numbers',
    integer: 'Please enter an integer',
    scope: 'The value range of this item is',
    min: 'Min is ',
    max: 'Max is '
  },
  login: {
    rememberPassword: 'Rember Password',
    login: 'Login',
    inputAccount: 'Input Account',
    loginSuccess: 'Login Success',
    check: 'Input account and password',
    title: 'User login'
  },
  header: {
    logout: 'Logout',
    changePwd: 'Change Password',
    originPwd: 'Original password',
    newPwd: 'New password',
    confirmPwd: 'Confirm password',
    inputPwdAgain: 'Enter password again',
    pwdNotMatch: 'The passwords entered twice do not match!',
    isConfigLogout: 'Are you sure you want to exit?'
  },
  admin: {
    roleName: 'Role name',
    adminStatus: 'Admin status',
    addAdmin: 'Add administrator',
    adminID: 'Admin ID',
    adminName: 'Admin Name'
  },
  addAdmin: {
    adminMsg: 'Administrator information',
    confirmPwd: 'Confirm password',
    role: 'Role',
    adminStatus: 'Admin status',
    realName: 'Actual name',
    inputPwdAgain: 'Enter password again',
    pwdNotMatch: 'The passwords entered twice do not match!',
    inputUsername: 'Please enter user name',
    chooseRole: 'Please select a role',
    chooseAdminStatus: 'Please select admin status'
  },
  editAdmin: {
    editAdminMsg: 'Modify administrator information',
    adminMsg: 'Administrator information',
    role: 'Role',
    adminStatus: 'Admin Status',
    realName: 'Actual name'
  },
  log: {
    diskSize: "Disk Size",
    diskAvailable: 'remaining space',
    saveLogDay: 'Number of days to keep logs',
    saveDays: 'Save Days',
    damagedZip: 'Damaged archive',
    title: 'Push stream log upload',
    log_upload_seq_describle: 'The log will be re-uploaded only if it is different from the last filled value',
    lids_describle: "LID is used to specify the device for uploading logs. The LID of multiple devices are separated by ';'",
    log_upload_seq_remind: 'log_upload_seq cannot be empty',
    log_upload_seq_remind2: 'log_upload_seq must be a numeric value',
    success: 'The log upload logo was modified successfully',
    log_upload_seq: 'upload batch',
    lids: 'Push-end LicenseID(LID)',
    empty: 'No data for the current date',
    analysis: 'Analysis',
    logName: 'Log name',
    mergerDownload: 'Package export',
    mergerDownloadAll: 'Package export all',
    serverUpload: 'Server log upload',
    server: 'Server',
    terminalUpload: 'Terminal log upload',
    terminal: 'Terminal',
    packageLog: 'Log uploaded',
    filterLog: 'Filter logs',
    lognote: 'Note: You can specify to upload the log of a server or terminal through this page; after selecting, it may take several minutes or more to upload; the uploaded log file will appear in the "Uploaded Log" list, and you can perform a separate file Downloads or "Package Downloads"'
  },
  logDownload: {
    chooseTimeTitle: 'Choose when to filter log content',
    clickDownload: 'Click the link to export',
    chooseDate: 'Select date',
    date: 'Filter logs based on time',
    analysisDetail: 'The iDoctor analysis results are as follows'
  },
  config: {
    displayMode: 'Display Mode',
    onDemand: 'On-demand Streaming',
    Disable_Interaction: 'Interactive properties',
    rtmpAmount: 'Number of retweet configurations',
    isPublish: 'Published',
    addgroupSuccess: 'The addition is successful, and it will take effect after the release',
    editgroupSuccess: 'The modification is successful, and it will take effect after the release',
    retweet: 'Retweet Config',
    mute: 'Mute',
    groupNamePlaceholder: 'Group Name',
    copyGroup: 'Please select the Group to copy',
    copyProject: 'Please select a Group to copy',
    inputServer: 'Please enter the server address',
    select_psl_verbose_log: 'Please select the push log level',
    select_ptcp_log: 'Please select a transport log level',
    select_SE_Name: 'Please select a scene name',
    select_HD_Decode_Flag: 'Please select a video decoding method',
    select_HD_Render_Flag: 'Please select a video rendering method',
    toSend: 'Release',
    sendAll: 'Publish All',
    title: 'Curren Group: ',
    profileAmount: 'Number of profiles',
    domainOrIp: 'Push stream destination server address',
    version: 'Config Version',
    addGroupConfig: 'Add Group',
    addProfile: 'Add Profile',
    send: 'Publish',
    sendSuccess: 'Publish Success',
    updateGroup: 'Update current group',
    deleteGroup: 'Delete current group',
    deleteProfile: 'Delete current PROFILE',
    confirmAddProfile: 'Confirm to add current PROFILE',
    PUSH_BASE_URL: 'Server Addr',
    PUSH_BASE_URL_manual: 'Server domain name or IP address',
    PSP_PEER_PUSH_URL_ARGS: 'Push Parameters',
    PSP_PEER_PUSH_URL_ARGS_manual: 'Push URL parameter',
    refresh_interval: 'refresh_interval',
    pzvt_refresh_interval: 'pzvt_refresh_interval',
    psl_verbose_log: 'Streaming log level',
    psl_verbose_log_manual: 'Streaming log level',
    ptcp_log: 'Transport log level',
    ptcp_log_manual: 'Transport log level',
    dump_yuv: 'dump_yuv',
    auto_start_push_play: 'auto_start_push_play',
    pce_port: 'pce_port',
    pce_data_port: 'pce_data_port',
    hb_interval: 'hb_interval',
    audio_healthy_threshold: 'audio_healthy_threshold',
    video_healthy_threshold: 'video_healthy_threshold',
    enable_video_preview: 'enable_video_preview',
    enable_ebur: 'enable_ebur',
    RC_TimeOut: 'RC_TimeOut',
    RC_Interval: 'RC_Interval',
    ARRC_Flag: 'ARRC_Flag',
    AR_Window: 'AR_Window',
    AR_RTT_Rate: 'AR_RTT_Rate',
    AR_Delta_Ignore: 'AR_Delta_Ignore',
    SE_Name: 'Scene Name',
    SE_Name_manual: 'Name of application scene',
    ROOM_SIZE: 'ROOM_SIZE',
    us_default_state: 'us_default_state',
    vbv_ratio: 'vbv_ratio',
    pbr_ratio: 'pbr_ratio',
    Min_BR: 'Minimum video bitrate',
    Min_BR_manual: 'Minimum video encoding bitrate',
    Max_BR: 'Maximum video bitrate',
    Max_BR_manual: 'Maximum video encoding bitrate',
    Max_FPS: 'Maximum fps',
    Max_FPS_manual: 'Maximum video encoding fps',
    Max_RES_WIDTH: 'Maximum resolution width',
    Max_RES_WIDTH_manual: 'Maximum video encoder resolution width',
    Max_RES_HEIGHT: 'Maximum resolution height',
    Max_RES_HEIGHT_manual: 'Maximum video encoder resolution height',
    aec: 'aec',
    ec_param_set: 'ec_param_set',
    HD_Decode_Flag: 'Video decoding mode',
    HD_Decode_Flag_manual: 'Video hard decoding or soft decoding',
    Video_Decode_Thread: 'Video_Decode_Thread',
    Video_Codec: 'Video_Codec',
    HD_Decode_Sync: 'HD_Decode_Sync',
    HD_Render_Flag: 'Video render mod',
    HD_Render_Flag_manual: 'Video hard render or soft rendering'
  },
  addGroup: {
    title: 'Add Group',
    clone: 'Copy',
    groupName: 'Group name',
    groupName_manual: 'Group name',
    groupNameRemind: 'Group name cannot be empty'
  },
  editServer: {
    ims_version: 'iMS version',
    datFileNameStandard: 'Please select a file named license.dat',
    check: 'Check',
    watch: 'Watch',
    same: 'project config is same',
    notSame: 'project config is not same',
    importProject: 'Import',
    chooseServerRemind: 'Please select a server first',
    stream_name: 'Stream Name',
    pushmonitor: 'Publish Stream',
    deleteResult: 'Delete Result',
    is_publish: 'Published',
    imsUploadRemind: 'Please upload the file with .tar.gz suffix',
    allUpgrade: 'Upgrade All',
    upgradeSuc: 'Update successed',
    uploadPackage: 'Upload update IMS package',
    updateSelected: 'Upgrade Selected',
    deleteDetail: 'Delete Detail',
    region: 'Region',
    serviceIP: 'Service IP',
    addServiceIP: 'Add Service IP',
    regionDialogTitle: 'Region Info',
    start_ip: 'Start IP',
    end_ip: 'End IP',
    regionNumber: 'Region Number',
    region_name: 'Region Name',
    addRegion: 'Add Region',
    add: 'Add Server',
    detail: 'Server Detail',
    certificate: 'Certificate',
    configureCert: 'Configure certificate',
    configureCertTitle: 'Configure a self-signed certificate (only for test page traffic)',
    submit: "The log upload request has been submitted. Please check or download the uploaded log file under the 'Uploaded Log' column; or go to the 'Fault Diagnosis Analysis' menu of 'iCare' to further diagnose and analyze the log.",
    is_main_imanager_server: 'Is main server',
    healthy: 'Health check',
    healthyState: 'Health status',
    serverName: 'Service name',
    serverStatus: 'Service status',
    suggestions: 'Recommended Action',
    logUpload: 'Log upload',
    uploadSuc: 'Log upload successfully',
    pushIp: 'Publish IP Address',
    pullIp: 'Pull IP Address',
    ndselect: 'Cluster Number',
    uid: 'License ID',
    addServer: 'Add Server',
    sendSelected: 'Release selected',
    serverIp: 'Server IP',
    sendGroup: 'Release Group',
    lastSendTime: 'Last release time',
    serverType: 'Service type',
    dialogTitle: 'Fill in the server information',
    sending: 'Distributing',
    send: 'Issued',
    sendResult: 'Release result',
    sendDetail: 'Release detail',
    chooseServer: 'Select server',
    groupSenddetail: 'Release details',
    managementPort: 'Management Port',
    dataPort: 'Data Port'
  },
  role: {
    roleName: 'Role Name',
    isDefault: 'Whether the system defaults',
    creator: 'Creator',
    addRole: 'add Role',
    roleID: 'Role ID',
    createTime: 'Create time'
  },
  addRole: {
    title: 'add Role',
    cardTitle: 'Role information',
    roleName: 'Role name',
    roleRight: 'Role Right',
    selectAll: 'Select all',
    cancelAll: 'Cancel all',
    roleNameRemind: 'Please enter a role name'
  },
  updateRole: {
    title: 'Modify role',
    cardTitle: 'Role information',
    roleName: 'Role name',
    roleRight: 'Role Right',
    selectAll: 'Select all',
    cancelAll: 'Cancel all',
    roleNameRemind: 'Please enter a role name'
  },
  project: {
    check: 'Examine',
    projectNamePlaceholder: 'Project Name',
    inputCustom: 'Enter a custom value',
    streaNnumber: 'Number of push channels',
    addProject: 'Add Project',
    correspond: 'Group / Profile'
  },
  addProject: {
    pzvt_refresh_interval: 'Virtual Clock Refresh Interval(ms)',
    expandParam: 'Expand Param',
    customizeMode: 'Custom collection work mode',
    ptcp_logsize_count: 'Streaming log count',
    ptcp_logsize_size: 'Streaming log size',
    psl_logsize_size: 'Transport log size',
    psl_logsize_count: 'Transport log count',
    res: 'Acquisition resolution',
    dialogTitle: 'Please enter a resolution',
    customRemind: 'You only need to fill in if you have customized the Custom plugin',
    title: 'Add Project',
    projectName: 'Project',
    inputProject: 'Please enter a Project name',
    select_psl_verbose_log: 'Please select the push log level',
    select_ptcp_log: 'Please select a transport log level',
    select_group: 'Please select a Group',
    select_profile: 'Please select a Profile',
    inputMode: 'Please enter the acquisition mode',
    inputVideo: 'Please enter video capture parameters',
    inputAudio: 'Please enter audio capture parameters',
    addStream: 'Add stream configuration',
    impl: 'Capturer Type',
    implCustom: 'Custom Capturer Type',
    format: 'Data Format',
    formatCustom: 'Custom Data Format',
    device: 'Custom device name',
    deviceCustom: '',
    deviceIndex: 'Device No.',
    fps: 'FPS',
    width: 'Capture Width',
    height: 'Capture Height',
    library: 'Extern Capturer Path',
    extern_param: 'Collect Extended Parameter'
  },
  editProject: {
    audioTrack: 'Audio Track',
    streamIndex: 'Stream Index',
    encodingFormat: 'Encoding format',
    logsize_count_range: 'The log size range is 0-1000',
    remindCutom: 'Please configure in the extended parameters',
    base: 'Base',
    video_enc_param: 'Video encoding',
    audio_enc_param: 'Audio encoding',
    advance: 'Advanced parameters',
    audio_sample_rate: 'Sample rate',
    audio_channel_num: 'Number of Channel',
    copyStream: 'Copy Stream',
    CustomAcquisitionParameters: 'Custom push parameters',
    title: 'Current Project：',
    global_configs: 'Base Configs',
    streaming_configs: 'Streaming Configs',
    video_capture_param: 'Video Codec',
    audio_capture_param: 'Audio Capturer',
    video_enc_type: 'Codec Name',
    colorSpace: 'Color Space',
    customcolorSpace: 'Custom Color Space',
    customized: 'Extern Custom Lib Path',
    working_mode: 'Capturer Working Mode',
    push_num: 'Enable Publish'
  },
  analysis: {
    pushTerminal: 'Push terminal',
    errMsg: 'Error message',
    error_server_list: 'Error server list',
    serial: 'Serial',
    lastRefresh: 'Last refresh time: ',
    streamName: 'Stream name',
    serverIp: 'Server IP',
    receiveIp: 'Incoming IP',
    reconnectFlag: 'Reconnect flag',
    forceUpdate: 'Force Update',
    qoeAnalysis: 'Analysising',
    qoeResult: 'QOE Analysis result',
    duration: 'Duration(s)',
    qoeBtn: 'QOE Analysis'
  },
  terminal: {
    clearError: 'Clear error message',
    msg_count: 'Error message',
    allow_download_license_remind: 'Certificate issued',
    cancel_allow_download_license_remind: 'Cancel the issuance of the certificate',
    allow_download_license: 'Issue a certificate',
    not_allow_download_license: 'Cancel issue a certificate',
    errMsg: 'Error message',
    serviveIP: 'Service IP',
    failTime: 'Activation failure reporting time',
    activeDetail: 'Error message',
    packageAndDownload: 'Package download activation file',
    packageAndDownloadAll: 'Package download all activation file',
    importLicenseBulk: 'Import authorization files in bulk',
    activate_filename: 'Active Filename',
    license_filename: 'License Filename',
    license_download_state: 'License Download status',
    downloaded: 'Downloaded',
    notDownloaded: 'Not downloaded',
    upgrade_msg: 'Upgrade Info',
    terminal_msg: 'Terminal Info',
    group_msg: 'Group Info',
    exportActive: 'Export Activation File',
    importLicense: 'Import License File',
    datFileNameStandard: 'Please select a dat file that conforms to the naming convention (eg: iLive-xxxxx-license.dat)',
    authStatus: 'Authorization status',
    inactivated: 'inactivated',
    certificateDownloaded: 'Certificate Downloaded',
    certificateNotDownloaded: 'Certificate Not Downloaded',
    certificateInvalid: 'Certificate Invalid',
    allow_download_license: 'Automatically issue certificates',
    sdk_version: 'Client version',
    package_name: 'Last upload package',
    uploadRemind: 'Uploaded successfully',
    updateRemind: 'Update successed',
    updateAll: 'Upgrade all',
    upgrade: 'Upgrade',
    uploadPackage: 'Upload upgrade file',
    adjustGroupSunc: 'The adjustment group was successful',
    addGroup: 'Add Group',
    mobileGroup: 'Mobile grouping',
    groupName: 'Group Name',
    modifyProject: 'Modify Project',
    name: 'Terminal ID',
    type: 'Type',
    lid: 'LID',
    status: 'Online/Offline',
    ip: 'IP',
    projectName: 'Project',
    logUpload: 'Log upload',
    logLevel: 'Log level',
    inputLid: 'Please fill in LID',
    submit: 'Log upload request submitted',
    restartDevice: 'Restart device',
    restartProcess: 'Restart process',
    sendSucc: 'Set, wait until client executes',
    last_upload_log_time: 'Last log upload time',
    terminalRemind: 'There is a terminal in the group, whether to confirm the deletion?',
    terminal_num: 'Number of terminals',
    terminalGroupPlace: 'Please select a group',
    converSucc: 'Covered successfully',
    converConfig: 'Publish to Terminals',
    wehreGroup: 'Group',
    pushChannel: 'Push Channel',
    br: 'BR',
    resolution: 'Resolvtion',
    streamID: 'Stream ID',
    pullURL: 'Pull URL',
    terminalNote: 'Note: After the terminal sets the LID and activates successfully, the activation file will be automatically uploaded to iManager, and the terminal status will be displayed as "Certificate not downloaded"; at this time, you need to select the corresponding terminal and select "Export Activation File" to activate the exported After the file package is provided to Powerinfo and the returned certificate file package is obtained from Powerinfo, select "Import Certificate File" to import the certificate file into iManager. After that, you can control which terminals are issued certificates through "Enable automatic certificate issuance" and "Close automatic certificate issuance", so that the terminal can be used normally.'
  },
  retweet: {
    sendSelected: 'Release selected',
    turn_push_type: 'Retweet Type',
    rtmpDialogTitle: 'Retweet configuration information',
    addr: 'Address',
    sendDialogTitle: 'Deliver configuration information',
    serverID: 'Server IP'
  },
  guide: {
    start: 'Open the floating window to play',
    close: 'Close the floating window to play'
  },
  montage: {
    title: 'Stitching mode',
    add: 'Add',
    w: 'Width',
    h: 'Height',
    output_param_index: 'Main screen number',
    output_param_mode: 'Stitching mode'
  },
  scene: {
    version: 'Current scene version number',
    zip: 'Upload scene file',
    click2Upload: 'Select file',
    remindText: 'If not selected, upload to all servers'
  },
  systemMsg: {
    imanagerV: 'iManager Version',
    iMSV: 'iMS Version',
    pushV: 'Pusher Version',
    pullV: 'Player Version',
    currentProject: 'Current Project',
    currentScene: 'Current Scene'
  }
}
export default en
