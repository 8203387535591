const state = {
  user: {
    SAID: 0,
    token: '',
    username: '',
    password: '',
    roleRight: ''
  },
  currentGroup: '',
  currentProject: '',
  version: '',
  terminalGroupID: '', // 终端分组ID，用于查询分组中的终端
  activeMenu: '' // 当前选中菜单
}

export default state
