import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugin/element.js'
import './assets/css/reset.css'
import './assets/css/customElement.css'
import i18n from '@/locales/index.js'
import globalLoading from './plugin/index'

Vue.use(globalLoading)

Vue.config.productionTip = false
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
