<template>
  <div>
    <HeaderBar />
    <MainPart />
  </div>
</template>

<script>
import HeaderBar from '../components/HeaderBar'
import MainPart from './Main'
export default {
  name: 'Home',
  components: {
    HeaderBar,
    MainPart
  }
}
</script>
