/**
 * path: "", path作为侧边栏是否被选中的index，若作为菜单，请输入完整的路径
 * right: "",
 * hasSub: false, 是否有子菜单
 * label: "" 侧边栏名称，label不为空则显示
 */
import Layout from '../views/Layout.vue'
export const routes = [
  {
    path: '/',
    label: {},
    hasSub: false,
    component: Layout,
    redirect: '/server'
  },
  {
    label: {
      zh: '服务器管理',
      en: 'Server Management'
    },
    hasSub: true,
    path: '/server',
    redirect: '/server/index',
    component: Layout,
    children: [
      {
        label: {
          zh: '服务器配置',
          en: 'Server Configuration'
        },
        hasSub: false,
        path: '/server/index',
        name: 'server',
        component: () => import('../views/server/Index.vue')
      },
      {
        label: {
          zh: '区域配置',
          en: 'Region Configuration'
        },
        hasSub: false,
        path: '/server/region',
        name: 'region',
        component: () => import('../views/server/Region.vue')
      },
      {
        label: {},
        hasSub: false,
        path: '/server/edit/:id',
        name: 'serverEdit',
        component: () => import('../views/server/Edit.vue')
      },
      {
        label: {},
        hasSub: false,
        path: '/server/add',
        name: 'serverAdd',
        component: () => import('../views/server/Add.vue')
      }
    ]
  },
  {
    label: {
      zh: 'Group管理',
      en: 'Group Management'
    },
    hasSub: false,
    path: '/group',
    component: Layout,
    redirect: '/group/index',
    children: [
      {
        label: {},
        hasSub: false,
        path: 'index',
        name: 'group',
        component: () => import('../views/group/Index.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'add_group',
        name: 'addGroup',
        component: () => import('../views/group/Add.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'group_detail',
        name: 'groupDetail',
        component: () => import('../views/group/GroupDetail.vue')
      },
      {
        label: {},
        hasSub: false,
        path: '/group/retweet/:groupname',
        name: 'retweet',
        component: () => import('../views/group/Retweet.vue')
      }
    ]
  },
  {
    label: {
      zh: 'Project管理',
      en: 'Project Management'
    },
    hasSub: false,
    path: '/project',
    redirect: '/project/index',
    component: Layout,
    children: [
      {
        label: {},
        hasSub: false,
        path: 'index',
        name: 'project',
        component: () => import('../views/project/Index.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'edit_project',
        name: 'editProject',
        component: () => import('../views/project/EditProject.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'add_project',
        name: 'addProject',
        component: () => import('../views/project/AddProject.vue')
      }
    ]
  },
  {
    label: {
      zh: '场景管理',
      en: 'Scene Management'
    },
    hasSub: false,
    path: '/scene',
    redirect: '/scene/index',
    component: Layout,
    children: [
      {
        label: {},
        hasSub: false,
        path: 'index',
        name: 'scene',
        component: () => import('../views/scene/Index.vue')
      }
    ]
  },
  {
    label: {
      zh: '终端管理',
      en: 'Terminal Management'
    },
    hasSub: true,
    path: '/terminal',
    redirect: '/terminal/index',
    component: Layout,
    children: [
      {
        label: {
          zh: '终端分组',
          en: 'Terminal Group'
        },
        hasSub: false,
        path: '/terminal/index',
        name: 'terminal',
        component: () => import('../views/terminal/Index.vue')
      },
      {
        label: {
          zh: '终端',
          en: 'Terminal'
        },
        hasSub: false,
        path: '/terminal/detail',
        name: 'terminal_detail',
        component: () => import('../views/terminal/Detail.vue')
      },
      {
        label: '',
        hasSub: false,
        path: '/terminal/push_channel/:id',
        name: 'push_channel',
        component: () => import('../views/terminal/PushChannel.vue')
      },
      {
        label: '',
        hasSub: false,
        path: '/terminal/failActive/:terminal_id',
        name: 'failActive',
        component: () => import('../views/terminal/FailActive.vue')
      },
      {
        label: '',
        hasSub: false,
        path: '/terminal/failActive',
        name: 'failActiveAll',
        component: () => import('../views/terminal/FailActive.vue')
      }
    ]
  },
  {
    label: {
      zh: 'iCare',
      en: 'iCare'
    },
    hasSub: true,
    path: '/icare',
    name: 'iCare',
    redirect: '/icare/quality_analysis',
    component: Layout,
    children: [
      {
        label: {
          zh: 'QOE质量分析',
          en: 'Quality Analysis'
        },
        hasSub: false,
        path: '/icare/quality_analysis',
        name: 'qualityAnalysis',
        component: () => import('../views/icare/Index.vue')
      },
      // {
      //   label: {
      //     zh: "FAQ",
      //     en: "FAQ"
      //   },
      //   hasSub: false,
      //   path: "/icare/faq",
      //   name: "faq",
      //   component: () => import('../views/icare/Faq.vue')
      // },
      // {
      //   label: {
      //     zh: "故障排查帮助",
      //     en: "Troubleshooting Help"
      //   },
      //   hasSub: false,
      //   path: "/icare/troubleshooting",
      //   name: "troubleshooting",
      //   component: () => import('../views/icare/Troubleshooting.vue')
      // },
      {
        label: {
          zh: '日志上传与分析',
          en: 'Log Upload And Analysis'
        },
        hasSub: false,
        path: '/icare/upload',
        name: 'logUpload',
        component: () => import('../views/icare/logUpload.vue')
      },
      // {
      //   label: {
      //     zh: '故障诊断分析',
      //     en: 'Fault Diagnosis'
      //   },
      //   hasSub: false,
      //   path: '/icare/download',
      //   name: 'logDownload',
      //   component: () => import('../views/icare/LogDownload.vue')
      // },
      {
        label: {
          zh: '新手指南',
          en: 'Guide'
        },
        hasSub: false,
        path: '/icare/guide',
        name: 'guide',
        component: () => import('../views/icare/Guide.vue')
      }
    ]
  },
  {
    label: {
      zh: '配置管理员',
      en: 'User Management'
    },
    hasSub: false,
    path: '/user',
    name: 'user',
    redirect: '/user/admin',
    component: Layout,
    children: [
      // {
      //   label: {
      //     zh: "角色",
      //     en: "User"
      //   },
      //   hasSub: false,
      //   path: "/user/role",
      //   name: "role",
      //   right: "1010100",
      //   component: () => import('../views/role/Role.vue')
      // },
      // {
      //   label: {},
      //   hasSub: false,
      //   path: 'add_role',
      //   name: 'addRole',
      //   right: "1010101",
      //   component: () => import('../views/role/AddRole.vue')
      // },
      // {
      //   label: {},
      //   hasSub: false,
      //   path: 'update_role',
      //   name: 'updateRole',
      //   right: "1010103",
      //   component: () => import('../views/role/UpdateRole.vue')
      // },
      {
        label: {},
        hasSub: false,
        path: 'admin',
        name: 'admin',
        right: '1010201',
        component: () => import('../views/admin/Admin.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'add_admin',
        name: 'addAdmin',
        right: '1010201',
        component: () => import('../views/admin/AddAdmin.vue')
      },
      {
        label: {},
        hasSub: false,
        path: 'edit_admin',
        name: 'editAdmin',
        right: '1010203',
        component: () => import('../views/admin/EditAdmin.vue')
      }
    ]
  },
  {
    label: {
      zh: '系统信息',
      en: 'System Message'
    },
    hasSub: false,
    path: '/system',
    redirect: '/system/index',
    component: Layout,
    children: [
      {
        label: {},
        hasSub: false,
        path: 'index',
        name: 'system',
        component: () => import('../views/system/Index.vue')
      }
    ]
  },
  {
    label: {},
    hasSub: false,
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    label: {},
    hasSub: false,
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]
