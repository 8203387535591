<template>
  <div class="container">
    <div class="left">
      <SiderBar />
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>

<script>
import SiderBar from '@/components/SiderBar'
export default {
  components: {
    SiderBar
  }
}
</script>

<style lang='scss' scoped>
.container {
  display: flex;
  .left {
    width: 200px;
    flex: none;
    height: calc(100vh - 79px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .right {
    width: 100%;
    height: calc(100vh - 79px);
    overflow-y: scroll;
    margin-left: 20px;
  }
}

.container /deep/ .el-divider__text {
  font-size: 20px;
}
.container /deep/ .el-divider--horizontal {
  margin: 34px 0;
}
.container /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
