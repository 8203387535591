const mutations = {
  addToken (state, token) {
    state.user.token = token
  },
  addSAID (state, SAID) {
    state.user.SAID = SAID
  },
  addUsername (state, username) {
    state.user.username = username
  },
  addPassword (state, password) {
    state.user.password = password
  },
  addRoleRight (state, roleRight) {
    state.user.roleRight = roleRight
  },
  updateCurrentGroup (state, currentGroup) {
    state.currentGroup = currentGroup
  },
  updateCurrentProject (state, currentProject) {
    state.currentProject = currentProject
  },
  updateVersion (state, version) {
    state.version = version
  },
  updateTerminalGroupID (state, terminalGroupID) {
    state.terminalGroupID = terminalGroupID
  },
  updateActiveMenu (state, activeMenu) {
    state.activeMenu = activeMenu
  }
}

export default mutations
