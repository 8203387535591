<template>
  <el-menu
    :default-active="defaultActive"
    class="wrap"
    background-color="#f4f4f4"
    text-color="#838383"
    active-text-color="#343434"
    @select="menuSelect"
  >
    <SiderbarItem
      v-for="item in menus"
      :key="item.name"
      :menu="item"
    />
  </el-menu>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { routes } from '@/router/routes.js'
import SiderbarItem from './SiderbarItem'
export default {
  components: {
    SiderbarItem
  },
  data () {
    return {
      defaultActive: '',
      menus: []
    }
  },
  computed: {
    ...mapGetters(['roleRight', 'username', 'password']),
    ...mapState(['activeMenu'])
  },
  watch: {
    activeMenu (newVal, oldVal) {
      if (newVal != oldVal) {
        this.defaultActive = newVal
      }
    }
  },
  created () {
    this.menus = this.tidyMenu(routes)
    // this.menus = routes
  },
  mounted () {
    this.defaultActive = location.hash.substring(1)
  },
  methods: {
    ...mapMutations(['updateTerminalGroupID']),
    // 菜单栏点击事件
    menuSelect (index) {
      if (index === '/terminal/detail') {
        this.updateTerminalGroupID('')
      }
      this.$router.push(index).catch(() => {})
    },
    // 整理菜单，去除多余的项
    tidyMenu (menu) {
      const temp = JSON.parse(JSON.stringify(menu))
      return temp.filter((item) => {
        if (item.hasSub) {
          item.children = this.tidyMenu(item.children)
          return true
        } else if (item.label.zh) {
          return item
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.wrap {
  height: 100%;
  width: 100%;
}
</style>
