import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import langEN from 'element-ui/lib/locale/lang/en'
import langZH from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
import i18n from '@/locales/index.js'
import '../assets/css/element-variables.scss'

import {
  Pagination,
  Dialog,
  Dropdown,
  Menu,
  Submenu,
  DropdownMenu,
  DropdownItem,
  MenuItem,
  MenuItemGroup,
  Input,
  Radio,
  Empty,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  DatePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tag,
  Tree,
  Alert,
  Icon,
  Upload,
  Card,
  Collapse,
  CollapseItem,
  Link,
  Divider,
  PageHeader,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui'
if (localStorage.lang === 'zh' || localStorage.lang === undefined) {
  locale.use(langZH)
  console.log(i18n.locale = 'zh')
} else {
  locale.use(langEN)
  console.log(i18n.locale = 'en')
}

Vue.use(Pagination)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Alert)
Vue.use(Icon)
Vue.use(Upload)
Vue.use(Card)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Link)
Vue.use(Divider)
Vue.use(PageHeader)
Vue.use(Empty)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.prototype.$ELEMENT = { size: 'mini' }
