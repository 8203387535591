import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store/index'
import router from '../router/index'
import language from './code'

let baseURL, timeout
const lang = localStorage.lang ? localStorage.lang : 'zh'
if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://172.16.0.126:5000/' // ceshi
  // baseURL = 'http://172.16.2.106:5000/' // tp_link3
  // baseURL = "http://175.178.84.254:5000/" // 穿透
  // baseURL = "http://47.108.141.90:8125/"
  // baseURL = "http://shouwang.free.idcfengye.com/"
} else {
  // 会发布到多个服务器
  baseURL = `${location.protocol}//${location.hostname}:${location.port}/`
}

export const customConfig = {
  baseURL,
  timeout: 180000
}

const service = axios.create(customConfig)

// request拦截器
service.interceptors.request.use((config) => {
  // 给每个请求都添加token
  config.headers.Token = store.getters.token
  return config
}, (err) => {
  Message.error(err)
  return Promise.reject(err)
})

// response拦截器
service.interceptors.response.use((res) => {
  switch (res.data.code) {
    case 0:
      return res
    case 1002: // 1002 登录过期跳转到登录页
      clearTimeout(timeout)
      // 解决登录过期时，提示一连串登录过期的问题
      timeout = setTimeout((error) => {
        Message.error(error)
        logout()
      }, 1000, language[lang][res.data.code])
      return res
    case 1005: // 服务器升级暂停操作
      MessageBox.alert(language[lang][1005], 'Remind', {
        showClose: false
      })
        .then(() => {
          logout()
        })
      return res
    case undefined:
      // 针对文件下载，若文件存在，则直接返回res，若文件不存在，则将响应的信息转换为json数据格式
      // 下载文件不存在时
      if (!res.headers['content-disposition']) {
        const reader = new FileReader() // 创建一个FileReader实例
        reader.readAsText(res.data, 'utf-8') // 读取文件,结果用字符串形式表示
        reader.onload = function () { // 读取完成后
          res.data = JSON.parse(reader.result)
          Message.error(language[lang][res.data.code])
        }
      }
      return res
    // 其他请求错误的情形
    default:
      Message.error(language[lang][res.data.code])
      return res
  }
}, (err) => {
  Message.error(err)
  return Promise.reject(err)
})

function logout () {
  store.commit('addToken', '')
  store.commit('addSAID', '')
  router.push('/login')
}

export default service
