const zh = {
  common: {
    open: '开启',
    close: '关闭',
    isConfirmSelectAll: '当前为全选状态是否确认操作？',
    selectAll: '全选',
    deselectAll: '取消全选',
    deleteResult: '删除结果',
    deleteDetail: '删除详情',
    empty: '暂无数据',
    searchSuc: '查询成功',
    resultShow: '结果展示',
    send: '下发',
    pullStream: '收流',
    detail: '详情',
    port: '端口',
    customize: '自定义',
    push: '推流端',
    pull: '收流端',
    pushAndPull: '推收流端',
    uploadSuc: '上传成功',
    online: '在线',
    offline: '离线',
    filter: '筛选',
    analysising: '正在分析',
    domain: '域名',
    ipRemind: '请输入正确的IP地址',
    required: '请填写该栏',
    check: '请按照要求填写表单',
    succ: '成功',
    fail: '失败',
    loading: '正在加载',
    deleteing: '正在删除',
    changing: '正在修改',
    systemName: 'iLive-Manager管理系统',
    account: '账号',
    password: '密码',
    newpass: '新密码',
    inputPassword: '请输入密码',
    confirm: '确认',
    cancel: '取消',
    add: '添加',
    changeSuccess: '修改成功',
    deleteSuccess: '删除成功',
    find: '查询',
    fresh: '刷新',
    deleteSelect: '删除选中',
    edit: '编辑',
    delete: '删除',
    email: '邮箱',
    operate: '操作',
    normal: '正常',
    disable: '禁用',
    remind: '提示',
    remark: '备注',
    isConfirmDelete: '是否确定删除?',
    isConfirmEdit: '是否确定修改?',
    confirmAdd: '确认添加',
    pleaseChoose: '请选择',
    addSuccess: '添加成功',
    confirmEdit: '确认修改',
    username: '用户名',
    updateSuccess: '更新成功',
    yes: '是',
    no: '否',
    import: '导入',
    export: '导出',
    importSuc: '导入成功',
    minis: '最小值为',
    maxis: '最大值为',
    notLess: '不能小于'
  },
  time: {
    to: '至',
    last1h: '最近1小时',
    last4h: '最近4小时',
    last24h: '最近24小时',
    last1day: '最近一天',
    last3days: '最近三天',
    last1week: '最近一周',
    last1month: '最近一月',
    timeRange: '时间段',
    startTime: '开始时间',
    endTime: '结束时间',
    startDate: '开始日期',
    endDate: '结束日期'
  },
  form: {
    number: '请输入数字',
    integer: '请输入整数',
    scope: '该项取值范围为 ',
    min: '最小值为 ',
    max: '最大值为 '
  },
  login: {
    rememberPassword: '记住密码',
    login: '登录',
    inputAccount: '请输入账号',
    loginSuccess: '登录成功',
    check: '请先输入账号和密码',
    title: '用户登录'
  },
  header: {
    logout: '退出登录',
    changePwd: '修改密码',
    originPwd: '原始密码',
    newPwd: '新密码',
    confirmPwd: '确认密码',
    inputPwdAgain: '请再次输入密码',
    pwdNotMatch: '两次输入密码不一致!',
    isConfigLogout: '确认退出吗？'
  },
  admin: {
    roleName: '角色名称',
    adminStatus: '管理员状态',
    addAdmin: '添加管理员',
    adminID: '管理员ID',
    adminName: '管理员名称'
  },
  addAdmin: {
    adminMsg: '管理员信息',
    confirmPwd: '确认密码',
    role: '角色',
    adminStatus: '管理员状态',
    realName: '真实姓名',
    inputPwdAgain: '请再次输入密码',
    pwdNotMatch: '两次输入密码不一致!',
    inputUsername: '请输入用户名',
    chooseRole: '请选择角色',
    chooseAdminStatus: '请选择管理员状态'
  },
  editAdmin: {
    editAdminMsg: '修改管理员信息',
    adminMsg: '管理员信息',
    role: '角色',
    adminStatus: '管理员状态',
    realName: '真实姓名'
  },
  log: {
    diskSize: "硬盘大小",
    diskAvailable: '剩余空间',
    saveLogDay: '已上传日志保存天数',
    saveDays: '保存天数',
    damagedZip: '损坏的压缩包',
    title: '推流端日志上传',
    log_upload_seq_describle: '需与上次填写的值不同才会重新上传日志',
    lids_describle: "LID用于指定上传日志的设备，多个设备的LID用';'隔开",
    log_upload_seq_remind: 'log_upload_seq不能为空',
    log_upload_seq_remind2: 'log_upload_seq必须为数字',
    success: '日志上传标识修改成功',
    log_upload_seq: '上传批次',
    lids: '推流端LicenseID(LID)',
    empty: '当前日期暂无数据',
    analysis: '分析',
    logName: '日志名称',
    mergerDownload: '打包下载',
    mergerDownloadAll: '全部打包导出',
    serverUpload: '服务器日志上传',
    server: '服务器',
    terminalUpload: '终端日志上传',
    terminal: '终端',
    packageLog: '已上传日志',
    filterLog: '筛选日志',
    lognote: '说明： 可通过该页面指定上传某服务器或终端的日志；选择后，可能需要几分钟或更长时间才能上传完毕；上传后的日志文件会出现在"已上传日志"列表中，可进行单独文件的下载或"打包下载"'
  },
  logDownload: {
    chooseTimeTitle: '选择筛选日志内容的时间',
    clickDownload: '点击链接进行导出',
    chooseDate: '选择日期',
    date: '基于时间筛选日志',
    analysisDetail: '经iDoctor分析结果如下'
  },
  config: {
    displayMode: '画面显示模式',
    onDemand: '按需推流',
    Disable_Interaction: '互动属性',
    rtmpAmount: '转推配置数量',
    isPublish: '是否已发布',
    addgroupSuccess: '添加成功，发布之后生效',
    editgroupSuccess: '修改成功，发布之后生效',
    retweet: '转推配置',
    mute: '静音',
    groupNamePlaceholder: 'Group 名称',
    copyGroup: '请选择要复制的Group',
    copyProject: '请选择要复制的Project',
    inputServer: '请输入服务器地址',
    select_psl_verbose_log: '请选择推流日志级别',
    select_ptcp_log: '请选择传输日志级别',
    select_SE_Name: '请选择场景名称',
    select_HD_Decode_Flag: '请选择视频解码方式',
    select_HD_Render_Flag: '请选择视频渲染方式',
    toSend: '发布',
    sendAll: '发布所有',
    title: '当前Group: ',
    profileAmount: 'Profile数量',
    domainOrIp: '推流目标服务器地址',
    version: '配置版本',
    addGroupConfig: '新增Group',
    addProfile: '添加Profile',
    send: '发布',
    sendSuccess: '发布成功',
    updateGroup: '更新当前Group',
    deleteGroup: '删除当前Group',
    deleteProfile: '删除当前PROFILE',
    confirmAddProfile: '确认添加当前PROFILE',
    PUSH_BASE_URL: '推流目标服务器地址',
    PUSH_BASE_URL_manual: '服务器域名或IP地址',
    PSP_PEER_PUSH_URL_ARGS: '推流参数',
    PSP_PEER_PUSH_URL_ARGS_manual: '推流URL参数',
    refresh_interval: 'refresh_interval',
    pzvt_refresh_interval: 'pzvt_refresh_interval',
    psl_verbose_log: '推流日志级别',
    psl_verbose_log_manual: '推流日志级别',
    ptcp_log: '传输日志级别',
    ptcp_log_manual: '传输日志级别',
    dump_yuv: 'dump_yuv',
    auto_start_push_play: 'auto_start_push_play',
    pce_port: 'pce_port',
    pce_data_port: 'pce_data_port',
    hb_interval: 'hb_interval',
    audio_healthy_threshold: 'audio_healthy_threshold',
    video_healthy_threshold: 'video_healthy_threshold',
    enable_video_preview: 'enable_video_preview',
    enable_ebur: 'enable_ebur',
    RC_TimeOut: 'RC_TimeOut',
    RC_Interval: 'RC_Interval',
    ARRC_Flag: 'ARRC_Flag',
    AR_Window: 'AR_Window',
    AR_RTT_Rate: 'AR_RTT_Rate',
    AR_Delta_Ignore: 'AR_Delta_Ignore',
    SE_Name: '场景名称',
    SE_Name_manual: '应用场景的名称',
    ROOM_SIZE: 'ROOM_SIZE',
    us_default_state: 'us_default_state',
    vbv_ratio: 'vbv_ratio',
    pbr_ratio: 'pbr_ratio',
    Min_BR: '最低视频码率',
    Min_BR_manual: '最低视频编码码率',
    Max_BR: '最高视频码率',
    Max_BR_manual: '最高视频编码码率',
    Max_FPS: '最高视频帧率',
    Max_FPS_manual: '最高视频编码帧率',
    Max_RES_WIDTH: '视频最大宽度',
    Max_RES_WIDTH_manual: '视频编码分辨率最大宽度',
    Max_RES_HEIGHT: '视频最大高度',
    Max_RES_HEIGHT_manual: '视频编码分辨率最大高度',
    aec: 'aec',
    ec_param_set: 'ec_param_set',
    HD_Decode_Flag: '视频解码方式',
    HD_Decode_Flag_manual: '使用硬解码还是软解码',
    Video_Decode_Thread: 'Video_Decode_Thread',
    Video_Codec: 'Video_Codec',
    HD_Decode_Sync: 'HD_Decode_Sync',
    HD_Render_Flag: '视频渲染方式',
    HD_Render_Flag_manual: '使用硬渲染还是软渲染'
  },
  addGroup: {
    title: '新增Group',
    clone: '复制',
    groupName: 'Group名称',
    groupName_manual: 'Group名称',
    groupNameRemind: 'Group名称不能为空'
  },
  editServer: {
    ims_version: 'iMS版本号',
    datFileNameStandard: '请选择命名为license.dat文件',
    check: '配置检查',
    watch: '查看',
    same: 'project配置相同',
    notSame: 'project配置不同',
    importProject: '导入',
    chooseServerRemind: '请先选择服务器',
    stream_name: '流名称',
    pushmonitor: '推流监测',
    deleteResult: '删除结果',
    is_publish: '是否已发布',
    imsUploadRemind: '请上传.tar.gz后缀的文件',
    allUpgrade: '全部升级',
    upgradeSuc: '升级成功',
    uploadPackage: '上传IMS更新包',
    updateSelected: '升级选中',
    deleteDetail: '删除详情',
    region: '区域',
    serviceIP: '服务IP',
    addServiceIP: '添加服务IP',
    regionDialogTitle: '区域信息',
    start_ip: '开始IP',
    end_ip: '结束IP',
    regionNumber: '区域编号',
    region_name: '区域名称',
    addRegion: '添加区域',
    add: '新增服务器',
    detail: '服务器详情',
    certificate: '证书',
    configureCert: '配置证书',
    configureCertTitle: '配置自签名证书（仅供测试页面收流使用）',
    submit: '日志上传请求已提交，请在“已上传日志”栏下查询或下载已上传的日志文件；或在“iCare”的“故障诊断分析”菜单中，进一步对日志进行故障诊断分析。',
    is_main_imanager_server: '是否为主服务器',
    healthy: '健康检测',
    healthyState: '健康状态',
    serverName: '服务名称',
    serverStatus: '服务状态',
    suggestions: '建议操作',
    logUpload: '日志上传',
    uploadSuc: '日志上传成功',
    pushIp: '推流IP',
    pullIp: '收流IP',
    ndselect: '拓扑编号',
    uid: '授权ID',
    addServer: '添加服务器',
    sendSelected: '发布选中',
    serverIp: '管理IP',
    sendGroup: '发布Group',
    lastSendTime: '最后下发时间',
    serverType: '服务类型',
    dialogTitle: '填写服务器信息',
    sending: '正在发布',
    send: '发布',
    sendResult: '发布结果',
    sendDetail: '发布详情',
    chooseServer: '选择服务器',
    groupSenddetail: '下发详情',
    managementPort: '管理端口',
    dataPort: '数据端口'
  },
  role: {
    roleName: '角色名称',
    isDefault: '是否系统默认',
    creator: '创建者',
    addRole: '添加角色',
    roleID: '角色ID',
    createTime: '创建时间'
  },
  addRole: {
    title: '添加角色',
    cardTitle: '角色信息',
    roleName: '角色名称',
    roleRight: '角色权限',
    selectAll: '全选',
    cancelAll: '全部取消',
    roleNameRemind: '请输入角色名称'
  },
  updateRole: {
    title: '修改角色',
    cardTitle: '角色信息',
    roleName: '角色名称',
    roleRight: '角色权限',
    selectAll: '全选',
    cancelAll: '全部取消',
    roleNameRemind: '请输入角色名称'
  },
  project: {
    check: '检查',
    projectNamePlaceholder: 'Project 名称',
    inputCustom: '请输入自定义参数',
    streaNnumber: '推流路数',
    addProject: '新增Project',
    correspond: 'Group / Profile'
  },
  addProject: {
    pzvt_refresh_interval: '虚拟时钟同步间隔(ms)',
    expandParam: '扩展参数',
    compatible_oloversion_setting: '兼容老版本配置',
    customizeMode: '自定义采集工作模式',
    ptcp_logsize_count: '传输日志数量',
    ptcp_logsize_size: '传输日志大小(MB)',
    psl_logsize_size: '推流日志大小(MB)',
    psl_logsize_count: '推流日志数量',
    res: '采集分辨率',
    dialogTitle: '请输入分辨率',
    customRemind: '定制了Custom插件,才需要填写',
    title: '新增Project',
    projectName: 'Project',
    inputProject: '请输入Project名称',
    select_psl_verbose_log: '请选择推流日志级别',
    select_ptcp_log: '请选择传输日志级别',
    select_group: '请选择Group',
    select_profile: '请选择Profile',
    inputMode: '请输入采集工作模式',
    inputVideo: '请输入视频采集参数',
    inputAudio: '请输入音频采集参数',
    addStream: '添加流配置',
    impl: '采集类型',
    implCustom: '自定义采集类型',
    format: '数据格式',
    formatCustom: '自定义数据格式',
    device: '设备名称',
    deviceCustom: '自定义设备名称',
    deviceIndex: '设备编号',
    fps: '帧率',
    width: '采集宽度',
    height: '采集高度',
    library: '外部采集模块路径',
    extern_param: '外部采集模块参数'
  },
  editProject: {
    audioTrack: '音轨',
    streamIndex: '流序号',
    encodingFormat: '编码格式',
    logsize_count_range: '日志大小范围为0-1000',
    remindCutom: '请在扩展参数中进行配置',
    base: '通用',
    video_enc_param: '视频编码',
    audio_enc_param: '音频编码',
    advance: '高级参数',
    audio_sample_rate: '采样率',
    audio_channel_num: '声道数',
    copyStream: '复制当前流',
    CustomAcquisitionParameters: '推流URL参数',
    title: 'Project：',
    global_configs: '基本配置',
    streaming_configs: '流配置',
    video_capture_param: '视频采集',
    audio_capture_param: '音频采集',
    video_enc_type: '视频编码类型',
    colorSpace: '色彩空间',
    customcolorSpace: '自定义色彩空间',
    customized: 'Extern Custom库文件路径',
    working_mode: '采集工作模式',
    push_num: '启动推流'
  },
  analysis: {
    pushTerminal: '推流终端',
    errMsg: '错误信息',
    error_server_list: '错误服务器列表',
    serial: '流序号',
    lastRefresh: '最后刷新时间: ',
    streamName: '流名称',
    serverIp: '服务器IP',
    receiveIp: '收流端IP',
    reconnectFlag: '重连标志',
    forceUpdate: '强制刷新',
    qoeAnalysis: '正在分析',
    qoeResult: 'QOE分析结果',
    duration: '持续时间(s)',
    qoeBtn: 'QOE分析'
  },
  terminal: {
    clearError: '清空错误信息',
    msg_count: '错误信息',
    allow_download_license_remind: '已下发证书',
    cancel_allow_download_license_remind: '已取消下发证书',
    allow_download_license: '开启自动下发证书',
    not_allow_download_license: '关闭自动下发证书',
    errMsg: '错误信息',
    serviveIP: '设备IP',
    failTime: '激活失败上报时间',
    activeDetail: '错误提示',
    packageAndDownload: '导出激活文件',
    packageAndDownloadAll: '批量下载全部激活文件',
    importLicenseBulk: '导入证书文件',
    activate_filename: '激活文件名',
    license_filename: '证书文件',
    license_download_state: '证书文件下载状态',
    downloaded: '已下载',
    notDownloaded: '未下载',
    upgrade_msg: '升级信息',
    terminal_msg: '终端信息',
    group_msg: '分组信息',
    exportActive: '导出激活文件',
    importLicense: '导入证书文件',
    datFileNameStandard: '请选择符合命名规范的dat文件（如：iLive-xxxxx-license.dat）',
    authStatus: '授权状态',
    inactivated: '未激活',
    certificateDownloaded: '已下载证书',
    certificateNotDownloaded: '未下载证书',
    certificateInvalid: '证书失效',
    allow_download_license: '自动下发证书',
    sdk_version: '客户端版本',
    package_name: '上次上传包',
    uploadRemind: '上传成功',
    updateRemind: '升级成功',
    updateAll: '全部升级',
    upgrade: '升级',
    uploadPackage: '上传升级文件',
    adjustGroupSunc: '调整分组成功',
    addGroup: '新增分组',
    mobileGroup: '移动分组',
    groupName: '分组名称',
    modifyProject: '修改Project',
    name: '终端ID',
    type: '类型',
    lid: 'LID',
    status: '在线/离线',
    ip: 'IP',
    projectName: 'Project',
    logUpload: '日志上传',
    logLevel: '日志级别',
    inputLid: '请填写LID',
    submit: '日志上传请求已提交',
    restartDevice: '重启设备',
    restartProcess: '重启进程',
    sendSucc: '已设置，等到客户端执行',
    last_upload_log_time: '上次日志上传时间',
    terminalRemind: '分组中存在终端，是否确认删除?',
    terminal_num: '终端数量',
    terminalGroupPlace: '请选择分组',
    converSucc: '覆盖成功',
    converConfig: '覆盖终端配置',
    wehreGroup: '分组',
    pushChannel: '推流频道',
    br: '码率',
    resolution: '分辨率',
    streamID: '流ID',
    pullURL: '收流URL',
    terminalNote: '说明：终端在设置LID并激活成功后，激活文件会自动上传到iManager，此时终端状态会显示为"未下载证书"；此时需要选择对应终端并选择"导出激活文件"，将导出的激活文件包提供给Powerinfo，并从Powerinfo获取到返回的证书文件包后，选择“导入证书文件"，将证书文件导入iManager。此后，可通过"开启自动下发证书”、“关闭自动下发证书”来控制对哪些终端下发证书，使得该终端可正常开始使用。'
  },
  retweet: {
    sendSelected: '发布选中',
    turn_push_type: '转推类型',
    rtmpDialogTitle: '转推配置信息',
    addr: '地址',
    sendDialogTitle: '下发配置信息',
    serverIP: '服务器IP'
  },
  guide: {
    start: '开启悬浮窗播放',
    close: '关闭悬浮窗播放'
  },
  montage: {
    title: '拼接模式',
    add: '拼接信息',
    w: '宽',
    h: '高',
    output_param_index: '主画面编号',
    output_param_mode: '拼接模式'
  },
  scene: {
    version: '当前场景版本号',
    zip: '上传场景文件',
    click2Upload: '选取文件',
    remindText: '不选则上传到所有服务器'
  },
  systemMsg: {
    imanagerV: 'iManager版本',
    iMSV: 'iMS版本',
    pushV: '推流端版本',
    pullV: '收流端版本',
    currentProject: '使用的Project',
    currentScene: '使用的场景'
  }
}
export default zh
