<template>
  <div class="menuItem">
    <el-submenu
      v-if="menu.hasSub"
      :index="menu.path"
    >
      <span slot="title">{{ menu.label[lang] }}</span>
      <el-menu-item-group>
        <SiderbarItem
          v-for="item in menu.children"
          :key="item.name"
          :menu="item"
        />
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item
      v-else
      :index="menu.path"
    >
      <span slot="title">{{ menu.label[lang] }}</span>
    </el-menu-item>
  </div>
</template>

<script>
export default {
  name: 'SiderbarItem',
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      lang: localStorage.lang
    }
  }
}
</script>

<style scoped>
.menuItem /deep/ .el-submenu .el-menu-item {
  min-width: 10px;
}
</style>
