import Vue from 'vue'
import VueRouter from 'vue-router'
// import Layout from '../views/Layout.vue'
import { routes } from './routes'
import store from '../store/index'

// 新页面打开imanager时，将本地存储的信息添加到vuex中
store.commit('addToken', localStorage.token)
store.commit('addSAID', localStorage.said)
store.commit('addUsername', localStorage.loginName)
store.commit('addRoleRight', localStorage.roleRight)

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

// 解决第一次登录会提示登录过期的问题
router.beforeEach((to, from, next) => {
  if (!localStorage.token && to.path == '/login') {
    next()
  } else if (!localStorage.token) {
    next('/login')
  } else {
    next()
  }
})

// // 根据权限添加路由
// function generateRouteByAuth() {
//   // console.log("添加路由", router)
//   userRoutes.forEach((item) => {
//     if (user.roleRight.includes(item.right)) {
//       router.addRoute("user", item);
//     }
//   });
//   router.addRoute({
//     path: '*',
//     name: '404',
//     component: () => import('../views/404.vue')
//   })
// }

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
export default router
