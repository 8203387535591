import { Loading } from 'element-ui'

// 全局通用loading
function globalLoading (Vue) {
  Vue.prototype.$globalLoading = function (text) {
    const loading = Loading.service({
      lock: true,
      text,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    return loading
  }
}

export default globalLoading
