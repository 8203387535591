<template>
  <div class="fixed">
    <div class="wrap">
      <div class="logo">
        {{ $t("common.systemName") }}
        <span class="version">{{ version }}</span>
      </div>
      <div class="info">
        <div class="name">
          {{ username || "user" }}
        </div>
        <el-dropdown trigger="click">
          <img
            src="../assets/imgs/Avatar.png"
            alt="avatar"
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">
              {{ $t("header.logout") }}
            </el-dropdown-item>
            <el-dropdown-item @click.native="dialogVisible = true">
              {{ $t("header.changePwd") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-dropdown class="showLang">
          <span>{{lang}}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in langOptions" :key="item.value" @click.native="chooseLang(item)"> {{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <el-dialog
      :title="$t('header.changePwd')"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="140px"
      >
        <el-form-item
          :label="$t('header.originPwd')"
          prop="originPwd"
        >
          <el-input
            v-model="ruleForm.originPwd"
            type="password"
          />
        </el-form-item>
        <el-form-item
          :label="$t('header.newPwd')"
          prop="newPwd"
        >
          <el-input
            v-model="ruleForm.newPwd"
            type="password"
          />
        </el-form-item>
        <el-form-item
          :label="$t('header.confirmPwd')"
          prop="confirmPwd"
        >
          <el-input
            v-model="ruleForm.confirmPwd"
            type="password"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmChange"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { changePass } from '../api/api.js'
export default {
  name: 'HeaderBar',
  data () {
    // 验证原始密码
    const validateOriginPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.inputPassword')))
      }
      callback()
    }
    // 验证新密码
    const validateNewPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.inputPassword')))
      } else {
        if (this.ruleForm.confirmPwd !== '') {
          this.$refs.ruleForm.validateField('confirmPwd')
        }
        callback()
      }
    }
    // 确认新密码是否一致
    const checkPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('header.inputPwdAgain')))
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error(this.$t('header.pwdNotMatch')))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      ruleForm: {
        originPwd: '',
        newPwd: '',
        confirmPwd: ''
      },
      rules: {
        originPwd: [{ validator: validateOriginPwd, trigger: 'blur' }],
        newPwd: [{ validator: validateNewPwd, trigger: 'blur' }],
        confirmPwd: [{ validator: checkPwd, trigger: 'blur' }]
      },
      lang: 'Language/中文',
      langOptions: [
        {
          value: 'zh',
          label: 'Language/中文'
        },
        {
          value: 'en',
          label: 'Language/English'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['username', 'SAID']),
    ...mapState(['version'])
  },
  created () {
    if (localStorage.lang === 'en') {
      this.lang = 'English/English'
    } else {
      this.lang = 'Language/中文'
    }
  },
  methods: {
    ...mapMutations(['addToken', 'addSAID']),
    // 退出登录
    logout () {
      this.$confirm(this.$t('header.isConfigLogout'), this.$t('header.logout'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel')
      })
        .then(() => {
          this.addSAID('')
          this.addToken('')
          this.$router.push('/login')
        })
        .catch(() => {})
    },
    // 确定修改密码
    confirmChange () {
      const param = {
        SAID: this.SAID,
        OldPassword: this.ruleForm.originPwd,
        Password: this.ruleForm.newPwd,
        Password1: this.ruleForm.confirmPwd
      }
      changePass(param)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(this.$t('common.changeSuccess'))
            this.ruleForm.originPwd = ''
            this.ruleForm.newPwd = ''
            this.ruleForm.confirmPwd = ''
          }
        })
        .catch((res) => {
          this.ruleForm.originPwd = ''
          this.ruleForm.newPwd = ''
          this.ruleForm.confirmPwd = ''
          this.$message.error(res)
        })
      this.dialogVisible = false
    },
    chooseLang (lang) {
      if (localStorage.lang == lang.value) return
      localStorage.lang = lang.value
      this.lang = lang.label
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.wrap {
  padding: 16px 40px 16px 40px;
  background-color: #3c8dbc;
  /* background-color: #304156; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    color: #fff;
    font-size: 24px;
    font-family: 'Kaushan Script', cursive;
    font-weight: 500;
  }
  .info {
    display: flex;
    align-items: center;
    .name {
      color: #fff;
      font-size: 16px;
      margin-right: 10px;
    }
    img {
      border-radius: 5px;
      width: 45px;
      height: 45px;
    }
  }
}
.showLang {
  color: #fff;
  margin-left: 20px;
}
.version {
  margin-left: 5px;
  font-size: 14px;
}
</style>
